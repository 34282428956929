import env from "react-dotenv";
import {parseJSON} from "../../helpers/json";
import {API_ENDPOINT} from '../../constants';

const API =  {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch((env ? API_ENDPOINT : '') + '/data/categories/tree', {
                method: 'GET', credentials:'include',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`


                },

            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        tree: result
                    }
            })
        }
    },



}
export default API;

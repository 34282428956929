import {Component} from "react";

import {Button, FormGroup} from 'reactstrap';

class Btn extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {


        return (
            <FormGroup className="position-relative">
                <Button className={this.props.className} onClick={this.props.onClick}>{this.props.children}</Button>
            </FormGroup>
        )
    }
}

export default Btn;

import {Component} from "react";

import {FormGroup, Label} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import x from '../../assets/x.svg';
import plus from '../../assets/plus.svg';


class List extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>

                <div className="list-field" style={this.props.height ? {height: this.props.height} : {}}>
                    <div className="items">
                        {this.props.value && this.props.value.length ?
                            this.props.value.map((item, idx) => {
                                return (
                                    <div>
                                        <span>{item}</span>
                                        <button onClick={() => {
                                            let value = this.props.value;
                                            value.splice(idx, 1);
                                            this.props.onChange(value);
                                        }}><Isvg src={x} /></button>
                                    </div>
                                )
                            })

                    :
                            null
                        }
                    </div>
                    <div className="input">
                        <input
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    if (!this.state.value){
                                        return;
                                    }

                                    if (!this.state.value.trim()){
                                        return;
                                    }

                                    let value = this.props.value;
                                    if (!value) {
                                        value = [];
                                    }
                                    value.push(this.state.value.trim());
                                    this.setState({value: ''})
                                    this.props.onChange(value);
                                }
                            }}
                            value={this.state.value}
                            onChange={(e) => this.setState({ value: e.target.value })}
                            onFocus={() => this.setState({ foucs: true })}
                            onBlur={() => this.setState({ foucs: false })}
                            type="text"
                            className="form-control"
                        ></input>
                        {!this.state.foucs ?
                            <Isvg src={plus}></Isvg>
                            :
                            null
                        }
                    </div>

                </div>

            </FormGroup>
        )
    }
}

export default List;

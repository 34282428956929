import {Component} from "react";

import {FormFeedback, FormGroup, Label} from 'reactstrap';

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>
                <input className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type={this.props.textType ? this.props.textType : "text"}
                    ref={(node) => this.props.setFieldRef(node)}
                    onKeyUp={(e) => {
                        if (e.key === this.props.finishKey) {
                            this.props.focusNext();
                        }
                    }}
                    onChange={(e) => {
                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = e.target.value;

                            this.props.onChange(value);
                            this.forceUpdate();
                        } else {
                            this.props.onChange(e.target.value);
                            //this.forceUpdate();

                        }

                    }}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                    placeholder={this.props.placeholder}
                ></input>
                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default Text;

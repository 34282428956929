import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import {required} from '../../form-builder/validation';
import {API_ENDPOINT} from '../../constants';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: [],

        }


    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

        let currDate = new Date();
        currDate.setHours(23);
        currDate.setMinutes(59);
        currDate.setSeconds(59);

        let fromDate = new Date(currDate);
        fromDate.setDate(currDate.getDate() - 7);
        currDate.setHours(0);
        currDate.setMinutes(0);
        currDate.setSeconds(0);



        this.setState({
            from: fromDate.toISOString().split('T')[0],
            to: currDate.toISOString().split('T')[0]
        })


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
    }

    export = () => {
        fetch(API_ENDPOINT + '/data/family-and-friends/export', {
            method: 'POST', credentials:'include',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },

            body: JSON.stringify({
                from: this.state.from,
                to: this.state.to
            })
        }).then(res => res.blob()).then(data => { if (data) window.open(URL.createObjectURL(data)) })

    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Family & Friends Codes'}</h1>

                    <div className="ff-export">
                        Period from
                        <input value={this.state.from} onChange={(val) => this.setState({from: val.target.value})} type="date"></input>
                        to
                        <input value={this.state.to} onChange={(val) => this.setState({to: val.target.value})} type="date"></input>
                        <button onClick={this.export} className="button">Export</button>
                    </div>
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false })}>
                            {'List'}
                        </button>
                        <button className="active" onClick={() => this.setState({ articleForm: {} })}>
                            {'Add new'}
                        </button>
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal"}
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product' :  'Add new'}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {

                                        if (this.state.articleForm && this.state.articleForm._id) {
                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Client',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'level',
                                                                            label: 'Name',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 12, sm: 12, xs: 12 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'code',
                                                                //             next: 'level',
                                                                //             label: 'Code',
                                                                //         },

                                                                //     ]
                                                                // },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'code',
                                                                            next: 'level',
                                                                            label: 'Password',
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'maxNumberOfUsers',
                                                                            next: 'level',
                                                                            label: 'Max number of users',
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'linkWith',
                                                                            next: 'level',
                                                                            label: 'Link with customer',
                                                                        },

                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete'}?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'List'   }
                                tab2Name={"Add new"   }
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })
									  this.updateMultipleParams([{ name: 'entries', value:"10" }, { name: 'page', value: 0 },{name: 'filter',value: ''}], true);
                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        articleForm: true,
                                        articlesList: false
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'name', label: 'NAME'   , allowSort: true },
                                    { type: 'text', name: 'code', label: 'CODE'   , allowSort: true },
                                    { type: 'text', name: '_count', label: 'NUMBER OF CLIENTS'    },
                                    { type: 'text', name: '_orders', label: 'NUMBER OF ORDERS'    },

                                    { type: 'text', name: '_sum', label: 'SUM'    },


                                ]}
                                items={this.state.items}

                                actions={
                                    [
                                        // {
                                        //     component: <Isvg src={editIcon} />,
                                        //     onClick: (item) => {
                                        //         this.setState({ articleForm: null }, () => this.setState({ articleForm: item }))

                                        //     }
                                        // },
                            {
                                component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => this.setState({deleteModal: item })
                                        },
                            ]
                                }

                            params={params}
                            sortField={params.sortField}
                            sortType={params.sortType}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div>
        )
    }
}

export default Page(Home);

/*

*/

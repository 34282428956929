import React, {Component} from 'react';


class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className={this.props.value ? 'toogle-switch toogle-switch-active' : 'toogle-switch'} onClick={() => this.props.onChange(!this.props.value)}>
                <div >
                    <div></div>
                </div>
                <span>{this.props.label}</span>
            </div>

        );
    }
}

export default Check;

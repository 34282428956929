import React, {Component} from 'react';
import Isvg from 'react-inlinesvg';
import {Link} from 'react-router-dom';

import logo from '../assets/logo.svg';
import logoutIcon from '../assets/logout.svg';
import {API_ENDPOINT} from '../constants';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownMenu: false,
            subDropdownMenu: false,
            rotateSubArrow: false
        }
    }

    render() {

        return (
            <div className={this.props.sidebarShort ? 'sidebar sidebar-short' : 'sidebar'}>
                <div className="sidebar-scroll-area">
                    <div className="sidebar-header">
                        <Isvg src={logo} className="logo"/>
                        {/* <div className="menu-icon-box">
                            <Isvg src={menuIcon} className="menu-icon" />
                        </div> */}
                    </div>
                    <div className="sidebar-body">
                        <ul className="main-menu">
                            {
                                this.props.modulesTree.map((item, idx) => {
                                    if (item.modules && item.modules.length === 0)
                                        return (
                                            <li>
                                                <div className="main-menu-block">
                                                    <Link to={item.path ? item.path : null}>
                                                        <div className='icon'>
                                                            {item.icon ? <Isvg
                                                                    src={API_ENDPOINT.replace('testapi', 'api') + item.icon}/>
                                                                : <></>}
                                                        </div>
                                                        <span className="link-text">{item.name}</span>
                                                    </Link>
                                                </div>
                                            </li>

                                        )
                                    else
                                        return (
                                            <li onClick={() => {
                                                this.setState({
                                                    menu: item._id
                                                })
                                            }}>
                                                <div
                                                    className={this.state.menu === item._id ? 'main-menu-block dropdown-down rotate' : 'main-menu-block dropdown-up'}>
                                                    <Link to={item.path ? item.path : null}>
                                                        <div className='icon'>
                                                            {item.icon ? <Isvg
                                                                    src={API_ENDPOINT.replace('testapi', 'api') + item.icon}/>
                                                                : <></>}
                                                        </div>
                                                        <span className="link-text">{item.name}</span>
                                                    </Link>
                                                </div>
                                                <ul className={this.state.menu === item._id ? 'main-menu-dropdown open' : 'main-menu-dropdown'}>
                                                    {
                                                        item.modules && item.modules.map((submodule, sidx) => {
                                                            return (
                                                                <li>
                                                                    <Link
                                                                        to={submodule.path ? submodule.path : item.path ? item.path : null}>{submodule.name}</Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>


                                            </li>
                                        )
                                })
                            }
                            <li style={{marginTop: 10}}>
                                <div className="main-menu-block main-menu-block-without-arrow"
                                     onClick={() => this.props.signOut(() => this.props[0].history.push('/login'))}>
                                    <a href={'#'}>
                                        <div className='icon'>

                                            <Isvg src={logoutIcon}/>
                                        </div>
                                        <span className="link-text">{'Logout'}</span>
                                    </a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;

/**
* Validation
* @author   Milan Stanojevic
*/
export const maxLength = (max, message) => value =>
  value && value.length > max ? message : undefined
export const minLength = (min, message) => value =>
value && value.length < min ? message : undefined
export const length = (len, message) => value =>
value && value.length !== len ? message : undefined
export const number = (message) => value =>
value && isNaN(Number(value)) ? message : undefined
export const minValue = (min, message) => value =>
  value && value < min ? message : undefined
export const maxValue = (max, message) => value =>
value && value > max ? message : undefined
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Email adresa nije validna' : undefined
export const required = (message='Obavezno polje!') => {
    return (value) => value ? undefined : message;
}

import {parseJSON} from "../../helpers/json";
import {API_ENDPOINT} from '../../constants';

const API =  {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/attributes', {
                method: 'POST', credentials:'include',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'api2': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/attributes/new', {
                method: 'POST', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/attributes/' + data.data, {
                method: 'DELETE', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/attributes/' + data.data._id, {
                method: 'PUT', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'api5': {
        onLoad: true,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/user-levels', {
                method: 'POST', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: null,
                    sortType: null,
                    sortField: null,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        userLevels: result,
                    }
            })
        }
    },
    'attributes': {
        onLoad: true,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/attributes', {
                method: 'POST', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: null,
                    sortType: null,
                    sortField: null,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        attributes: result,
                    }
            })
        }
    },



    'validate': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/attributes/validate/' + data.value + '/' + data.id, {
                method: 'GET', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    }

}
export default API;

import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import {required} from '../../form-builder/validation';
import {API_ENDPOINT} from '../../constants';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: []
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
    }

    export = () => {
        fetch(API_ENDPOINT + '/data/categories/export', {
            method: 'GET', credentials:'include',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        }).then(res => res.blob()).then(data => { if (data) window.open(URL.createObjectURL(data)) })
    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Categoires'   }</h1>

                    <div className='buttons'>
                    {/*    <div className="ff-export">*/}
                    {/*        <button className='btn-primary' onClick={this.export}><div className="icon"><Isvg src={exportIcon} /></div>Export excel</button>*/}
                    {/*    </div>*/}
                    {/*    <FileField label={'Import excel'} endpoint={'/data/categories/import'}></FileField>*/}

                    </div>

                </div>

                {
                    this.state.articleForm ?
                        <div className="table-tabs">
                            <button onClick={() => this.setState({ articleForm: false })}>
                                {'Categories list'}
                            </button>
                            <button className="active" onClick={() => this.setState({ articleForm: {} })}>
                                {'Add new category'}
                            </button>
                        </div>
                        : null
                }

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal"   }
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product'    :  'Add new'   }
                                    lang={this.props.lang}
                                    onSubmit={(dat) => {
                                        let data = { ...dat }

                                        data.attributes = [];
                                        data.filters = [];
                                        data.namingAttributes = [];
                                        if (data.attributeKey) {
                                            for (var key in data.attributeKey) {
                                                if (data.attributeKey[key]) {
                                                    data.attributes.push(key);
                                                }
                                            }
                                            delete data.attributeKey;
                                        }

                                        if (data.filterKey) {
                                            for (var key in data.filterKey) {
                                                if (data.filterKey[key]) {
                                                    data.filters.push(key);
                                                }
                                            }
                                            delete data.filterKey;
                                        }
                                        if (data.namingAttributeKey) {
                                            for (var key in data.namingAttributeKey) {
                                                if (data.namingAttributeKey[key]) {
                                                    data.namingAttributes.push(key);
                                                }
                                            }
                                            delete data.namingAttributeKey;
                                        }


                                        if (this.state.articleForm && this.state.articleForm._id) {
                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Category details' ,
                                                            style: { paddingBottom: 33 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'password',
                                                                            label: 'Name',
                                                                            multilang: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Name is required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'slug',
                                                                            next: 'password',
                                                                            label: 'Slug',
                                                                            multilang: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Slug is required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'breadcrumb',
                                                                            next: 'password',
                                                                            label: 'Breadcrumb',
                                                                            multilang: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Breadcrumb is required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'position',
                                                                            next: 'password',
                                                                            label: 'Position',
                                                                            asyncValidation: true,
                                                                            validate: []
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'html',
                                                                            height: 200,
                                                                            name: 'description',
                                                                            next: 'password',
                                                                            label: 'Description',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'homeImage.xs',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Home image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'icon',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Icon',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'hidden',
                                                                            next: 'stock',
                                                                            label: 'Hidden',
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'featuredOnHomePage',
                                                                            next: 'stock',
                                                                            label: 'Featured on home page',
                                                                        }
                                                                    ]
                                                                },
                                                            ]
                                                        },



                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Attributes' ,
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                ...this.state.attributes.map((item) => {
                                                                    return {

                                                                        type: 'col',
                                                                        width: { lg: 3, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'switch',
                                                                                name: 'attributeKey.' + item.key,
                                                                                label: item.name[this.props.lang],

                                                                            }
                                                                        ]



                                                                    }
                                                                })

                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Filters' ,
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                ...this.state.attributes.map((item) => {
                                                                    return {

                                                                        type: 'col',
                                                                        width: { lg: 3, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'switch',
                                                                                name: 'filterKey.' + item.key,
                                                                                label: item.name[this.props.lang],

                                                                            }
                                                                        ]



                                                                    }
                                                                })

                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Naming Attributes' ,
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                ...this.state.attributes.map((item) => {
                                                                    return {

                                                                        type: 'col',
                                                                        width: { lg: 3, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'switch',
                                                                                name: 'namingAttributeKey.' + item.key,
                                                                                label: item.name[this.props.lang],

                                                                            }
                                                                        ]



                                                                    }
                                                                })

                                                            ]
                                                        }
                                                    ]
                                                }


                                            ],
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete customer' } <strong>{this.state.deleteModal ? this.state.deleteModal.name[this.props.lang] : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Categories list' }
                                tab2Name={"Add new category" }
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })
                                    this.updateMultipleParams([{ name: 'entries', value: "10" }, { name: 'page', value: 0 }, { name: 'filter', value: '' }], true);
                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        articleForm: true,
                                        articlesList: false
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'name', label: 'NAME' , multilang: true, allowSort: true },
                                    { type: 'text', name: 'breadcrumb', label: 'BREADCRUMB' , multilang: true, allowSort: true },

                                ]}
                                items={this.state.items}

                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} />,
                                            onClick: (item) => {
                                                if (item.attributes) {
                                                    for (let i = 0; i < item.attributes.length; i++)
                                                        item['attributeKey.' + item.attributes[i]] = true;
                                                }
                                                if (item.filters) {
                                                    for (let i = 0; i < item.filters.length; i++)
                                                        item['filterKey.' + item.filters[i]] = true;
                                                }
                                                if (item.namingAttributes) {
                                                    for (let i = 0; i < item.namingAttributes.length; i++)
                                                        item['namingAttributeKey.' + item.namingAttributes[i]] = true;
                                                }

                                                this.setState({ articleForm: null }, () => this.setState({ articleForm: item }))

                                            }
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div >
        )
    }
}

export default Page(Home);

/*

*/

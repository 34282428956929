import React, {Component} from 'react';
import {FormGroup, Label} from 'reactstrap';

import {Editor} from '@tinymce/tinymce-react';

/**
* Component for html field
* @author   Milan Stanojevic
*/
class HtmlImage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}

                <Editor
                    tinymceScriptSrc='/tinymce/tinymce.min.js'
                    disabled={this.props.disabled}
                    apiKey="os62u3rj8cpp90llk5fb3btu85zx9vez9a6b7fmewvga76rh"
                    init={{
                        plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css'}}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = val;

                            this.props.onChange(value);
                        } else {

                            this.props.onChange(val);
                        }
                        this.forceUpdate();


                    }} />

            </FormGroup>




        );
    }
}

export default HtmlImage;

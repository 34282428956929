import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import Isvg from 'react-inlinesvg';

import logo from '../../assets/logo.svg';
import {API_ENDPOINT} from '../../constants';

class LoginPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            items: [{
                name: 'Test'
            }]
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {

    }
    login = (data) => {

        fetch(API_ENDPOINT + "/users/login", {
            method: 'POST', credentials:'include',
            credentials:'include',
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error
                    })
                } else {

                    localStorage.setItem("authToken", result.token);

                    fetch(API_ENDPOINT + '/users/modules/available-paths', {
                        method: 'GET', credentials:'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                        },
                      }).then(res => {
                        return res.json()
                      }).then((result) =>   {
                        if (result.length){
                            this.props.verifyUser(() => this.props[0].history.push(result[0]));
                        }else{
                            this.props.verifyUser(() => this.props[0].history.push('/articles'));
                        }
                    }).catch((err) => {
                        this.props.verifyUser(() => this.props[0].history.push('/articles'));
                    });





                }
            });
    }

    render() {
        let params = this._getParams();


        return (
            <div>

                <section className="login-page">
                    <div className="login-page-box-container">
                        <div className="login-page-box">
                            <Isvg src={logo} className="logo" />
                            <FormBuilder
                                addButtonText={'Add'}
                                onSubmit={(data) => {
                                    this.login(data)
                                }}
                                initialValues={{

                                }}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 8 },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'username',
                                                        next: 'password',
                                                        placeholder: 'Username',
                                                        asyncValidation: true,
                                                        validate: [
                                                            (value, values) => { if (!value || value === '') return "Wrong username!" }
                                                        ]
                                                    },
                                                    {
                                                        type: 'text',
                                                        textType: 'password',
                                                        name: 'password',
                                                        placeholder: 'Password',
                                                        next: 'save',
                                                        asyncValidation: true,
                                                        validate: [
                                                            (value, values) => { if (!value || value === '') return "Wrong password!" }
                                                        ]
                                                    },
                                                    {
                                                        type: 'switch',
                                                        name: 'remember',
                                                        label: 'Remember me',
                                                        placeholder: 'Šifra',
                                                    },

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 12 },
                                                children: [
                                                    {
                                                        type: 'button',
                                                        action: 'submit',
                                                        children: 'LOG IN',
                                                        className: ''
                                                    },
                                                ]
                                            }
                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Page(LoginPage);

import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {Col, Container, Row} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import { required} from '../../form-builder/validation';

class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            items: [

            ],
            positions: []
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>People</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Save</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete discount code <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                lang={this.props.lang}
                                addButtonText={'Add'}
                                onSubmit={(dat) => {

                                    let data = { ...dat };



                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._api('api2', { data: data }, () => {
                                            this.setState({ clientForm: {} }, () => { this._onLoad() })
                                        });
                                    }
                                }}
                                initialValues={this.state.form ? this.state.form : null}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Employee',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        next: 'level',
                                                                        label: 'Name',
                                                                        multilang: false,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'position',
                                                                        next: 'level',
                                                                        label: 'Position',
                                                                        multilang: false,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ],
                                                                        values: [{name: 'Choose', value: null}, ...this.state.positions.map((item, idx) => {
                                                                            return {
                                                                                name: item.name[this.props.lang],
                                                                                value: item._id
                                                                            }
                                                                        })]
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'description',
                                                                        next: 'level',
                                                                        label: 'Description',
                                                                        multilang: true,
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'phone',
                                                                        next: 'level',
                                                                        label: 'Phone',
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'email',
                                                                        next: 'level',
                                                                        label: 'Email',
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'image',
                                                                        next: 'level',
                                                                        label: 'Image',
                                                                        endpoint: '/data/team/upload',

                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'sortPosition',
                                                                        next: 'level',
                                                                        label: 'Position',
                                                                        validate: []
                                                                    },

                                                                ]
                                                            },




                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'NAME', multilang: false, allowSort: true },
                                        { type: 'text', name: 'email', label: 'EMAIL', multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {




                                                    this.setState({ form: null }, () => this.setState({ form: item }))

                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);

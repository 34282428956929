import {Component} from "react";

import {Button, FormGroup} from 'reactstrap';
import QRCode from "react-qr-code";

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    downloadQR = () => {
        // const svg = document.getElementById("qrcode2");
        // const svgData = new XMLSerializer().serializeToString(svg);
        // const canvas = document.createElement("canvas");
        // const ctx = canvas.getContext("2d");
        // const img = new Image();
        // img.onload = () => {
        //     canvas.width = img.width;
        //     canvas.height = img.height;
        //     ctx.drawImage(img, 0, 0);
        //     const pngFile = canvas.toDataURL("image/png");

        //     const downloadLink = document.createElement("a");
        //     downloadLink.download = this.props.sku;
        //     downloadLink.href = `${pngFile}`;
        //     downloadLink.click();
        // };

        // img.src = "data:image/svg+xml;base64," + btoa(svgData);



        const svg = document.getElementById("qrcode2");
        const svgData = new XMLSerializer().serializeToString(svg);


        const downloadLink = document.createElement("a");
        downloadLink.download = this.props.sku + '.svg';
        downloadLink.href = `data:text/html;base64,${window.btoa(svgData)}`;
        downloadLink.click();


        // const canvas = document.createElement("canvas");
        // const ctx = canvas.getContext("2d");
        // const img = new Image();
        // img.onload = () => {
        //     canvas.width = img.width;
        //     canvas.height = img.height;
        //     ctx.drawImage(img, 0, 0);
        //     const pngFile = canvas.toDataURL("image/png");

        //     const downloadLink = document.createElement("a");
        //     downloadLink.download = this.props.sku;
        //     downloadLink.href = `${pngFile}`;
        //     downloadLink.click();
        // };

        // img.src = "data:image/svg+xml;base64," + btoa(svgData);


    };

    render() {
        return (
            <FormGroup className="position-relative qr-code" style={{ padding: 15 }}>
                {this.props.value && this.props.value.sv ?
                    <QRCode id="qrcode2" value={`http://venturedesign.se/products/${this.props.sku}/${this.props.value.sv}`} />
                    :
                    null
                }
                {this.props.value && this.props.value.sv ?

                    <Button color="primary" type="button" onClick={this.downloadQR}>Download</Button>
                    :
                    null
                }
            </FormGroup>
        )
    }
}

export default Text;

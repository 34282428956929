import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {Col, Container, Row} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import {required} from '../../form-builder/validation';

class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            items: [

            ]
        }
    }

    componentDidMount() {
       this._onLoad();
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props[0].location.search !== prevProps[0].location.search){
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Translate cache</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Save</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm:{} }, () => { this._onLoad();this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete translation <strong>{this.state.deleteModal ? this.state.deleteModal.name:''}</strong> ?
                </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                             registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                lang={this.props.lang}
                                addButtonText={'Add'   }
                                    onSubmit={(dat) => {

                                        let data = {...dat};



                                        if (this.state.form && this.state.form._id) {

                                            this._api('api4', { data: data }, () => {
                                                this.setState({ form: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {

                                            this._api('api2', { data: data }, () => {
                                                this.setState({ clientForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.form ? this.state.form : null}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Translation'   ,
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'key',
                                                                            next: 'level',
                                                                            disabled: true,
                                                                            label: 'Key',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'en',
                                                                            next: 'level',
                                                                            label: 'EN',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'sv',
                                                                            next: 'level',
                                                                            label: 'SV',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ]
                                                                        },

                                                                    ]
                                                                },


                                                            ]
                                                        },

                                                    ]
                                                }
                                            ]
                                        },


                                    ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'en', label: 'EN' , allowSort: true },
                                        { type: 'text', name: 'sv', label: 'SV' , allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {




                                                     this.setState({ form: null }, () => this.setState({form: item}))

                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                             </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);

import Screen from './screen';
import API from './api';
import Login from '../login/screen';

const FFCodes =  {
    path: '/ff-codes',
    auth: true,
    component: Screen,
    preAuthComponent: Login,
    api: API,
    seo: (data, lang) => { return {} }
}
export default FFCodes;

import Screen from './screen';

const LoginPage =  {
    path: '/login',
    auth: false,
    component: Screen,
    seo: (data, lang) => {
        return {}
    }
}
export default LoginPage;

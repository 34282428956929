import React, {Component} from 'react';
import {Button, FormGroup} from 'reactstrap';

import exportIcon from '../../assets/export.svg';
import axios from 'axios';
import Isvg from 'react-inlinesvg';
import {API_ENDPOINT} from '../../constants';


/**
* Component for import files
* @author   Milan Stanojevic
*/
class File extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {


            let formData = new FormData();
            formData.append('file', input.files[0]);

            this.setState({ _loading: '0%' })

            axios.post(API_ENDPOINT + this.props.endpoint, formData, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                },
                onUploadProgress: (progressEvent) => {
                    this.setState({ _loading: Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%' })

                }
            }).then((response) => {
                if (response.status !== 200) {
                    this.setState({ error: response.data, _loading: null })
                } else {
                    this.props.onChange(response.data);
                    this.setState({
                        _loading: null
                    })
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status !== 200)
                        this.setState({ error: error.response.data, _loading: null })

                }
            })



        }
    }

    render() {
        return (
            <FormGroup className="file-upload-wrap">
                <input type="file" onChange={this.selectFile} />
                <Button color="primary"><div className="icon"><Isvg src={exportIcon} /></div> {this.props.value ? this.props.value.split('/').pop() : <span>{this.state._loading ? this.state._loading : this.props.label}</span>}</Button>
                {this.state.error ?
                    <p className="error">{this.state.error}</p>
                    :

                    null
                }


            </FormGroup>


        );
    }
}

export default File;

import React from 'react';

import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

import error from '../assets/error.png';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} >

                <ModalBody className="delete-modal">
                    <img src={error}  alt={''}/>
                    <div className="message">
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="danger" onClick={() => this.props.handler()}>{'Yes'   }</Button>{' '}
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'No' }</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;

import {parseJSON} from "../../helpers/json";
import {API_ENDPOINT} from '../../constants';

const API =  {
    'load': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/home-config', {
                method: 'GET', credentials:'include',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        form: result,
                    }
            })
        }
    },

    'save': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/home-config', {
                method: 'POST', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
}
export default API;

import {parseJSON} from "../../helpers/json";
import {API_ENDPOINT} from '../../constants';

const API =  {
    'loadAd': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/adds', {
                method: 'POST', credentials:'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                    }    ,

                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,

                    })

                }).then(parseJSON).then(({ result, status }) => {

                        return {
                            items: result.items,
                        total: result.total
                        }


                })
        }
    },

    'makeAd': {

        onLoad: false,
        action: (data) => {
			data.data.expired='false';
            return fetch(API_ENDPOINT + '/data/listad/new', {
                method: 'POST', credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },
        'updateAd': {
            onLoad: false,
            action: (data) => {

                return fetch(API_ENDPOINT + '/data/adds/update/' + data.data._id+'/'+data.langs, {
                    method: 'PUT', credentials:'include',
                    credentials:'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify(data.data)
                }).then(parseJSON).then(({ result, status }) => {
                    return { result, status };
                })
            }
        },

        'deleteAd': {
            onLoad: false,
            action: (data) => {
                return fetch(API_ENDPOINT + '/data/adds/delete/' + data.data, {
                    method: 'POST', credentials:'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    //body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    return { result, status };
                })
            }
        }


}
export default API;

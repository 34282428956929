import React from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import Isvg from 'react-inlinesvg';

import dragIcon from '../../assets/drag.svg';

import {SortableContainer, SortableElement, sortableHandle,} from 'react-sortable-hoc';

var _ = require('lodash');
const DragHandle = sortableHandle(() => <span><Isvg src={dragIcon} /></span>);

const SortableItem = SortableElement(({ value, lang, className, path, update, updateActiveIndex, activeElement }) => <li className={activeElement === path ? 'dragged' : ''}> <h6> <DragHandle />{Object.translate(value, 'name', lang)}</h6>
    {
        value.children && value.children.length ?
            <SortableList activeElement={activeElement} onSortStart={({node, index}) => updateActiveIndex(path, index)} useDragHandle update={update} hideSortableGhost={false} lang={lang} path={path}
                onSortEnd={(data) => update(path, data)}
                items={value.children}></SortableList>
            :
            null
    }

</li>);

const SortableList = SortableContainer(({ items, lang, path, update, updateActiveIndex, activeElement }) => {
    return (
        <ul className="sort-list">
            {items.map((value, index) => (
                <SortableItem parent={index} activeElement={activeElement} updateActiveIndex={updateActiveIndex} update={update} path={(path ? path + '.' : '') + index} key={`item-${path}-${index}-${value._id}`} index={index} value={value} lang={lang} itemIndex={index} />
            ))}
        </ul>
    );
});


class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            tree: []
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
    }

    onSortEnd = (path, { oldIndex, newIndex }) => {
        let state = JSON.parse(JSON.stringify(this.state));

        if (path) {
            let splitted = String(path).split('.');

            let newPath = 'tree' + splitted.map((item) => `[${item}].children`).join('');
            let tmp = _.get(state, newPath + `[${oldIndex}]`);
            _.set(state, newPath + `[${oldIndex}]`, _.get(state, newPath + `[${newIndex}]`))
            _.set(state, newPath + `[${newIndex}]`, tmp)

            this.setState({ tree: JSON.parse(JSON.stringify(state.tree)) })

        } else {

            let tmp = state.tree[oldIndex];
            state.tree[oldIndex] = state.tree[newIndex];
            state.tree[newIndex] = tmp;

            this.setState({
                tree: state.tree
            });

        }
        this.setState({
            activeElement: null
        })



    }

    updateActiveIndex = (path, index) => {
        this.setState({
            activeElement: (path ? path + '.' : '') + index
        })

    }

    render() {
        let params = this._getParams();


        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Categories' }</h1>
                </div>
                <div className="sortable-hoc">
                    <SortableList
                        useDragHandle
                        hideSortableGhost={false}
                        lang={this.props.lang}
                        items={this.state.tree}
                        onSortStart={({node, index}) => {
                            this.updateActiveIndex('', index);
                        }}
                        onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd('', { oldIndex, newIndex })}
                        path={''}
                        update={this.onSortEnd}
                        updateActiveIndex={this.updateActiveIndex}
                        activeElement={this.state.activeElement}
                    />

                </div>


            </div>
        )
    }
}

export default Page(Home);

/*

*/

import React from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {Button, Col, Container, Input, Label, Row} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import DeleteModal from '../../components/deleteModal';
import moment from 'moment';

class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            items: [

            ],
            positions: [],
            orderShown: true,
            orderDetailsShown: false
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
        /*
        if(this.state.form && this.state.form.items && this.state.form.items.length !== 0){
            let sum =


        }
        */
        // {
        //     this.state.form && this.state.form.items.length !== 0 ?
        //         (this.state.form.items.reduce((sum, current) => {
        //             let res;
        //             if (current && current.price) {
        //                 res = sum + current.price.vat;

        //             }
        //             return res
        //         }, 0), true, true)
        //         :
        //         '00,00'
        // }
    }

    render() {
        let params = this._getParams();

        return (
            <div>

                <div>

                    <div className="section-title title-options" style={{ justifyContent: 'unset' }}>
                        {
                            this.state.orderDetailsShown ?
                                <><div className="arrow-back" onClick={() => {
                                    this.setState({
                                        orderDetailsShown: false,
                                        orderShown: true
                                    })
                                }}></div></>
                                :
                                null
                        }
                        <h1 style={this.state.orderDetailsShown ? { marginLeft: 20 } : null}>{this.state.orderShown ? 'Order' : 'Order details'}</h1>
                    </div>

                    {
                        this.state.orderShown ?
                            <Container fluid style={{ paddingRight: 0 }}>
                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {
                                        this._api('api3', { data: this.state.deleteModal._id }, () => {
                                            this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                        });
                                    }} >
                                    Delete order <strong>{this.state.deleteModal ? this.state.deleteModal.orderNumber : ''}</strong> ?
                                </DeleteModal>
                                <Row>
                                    <Col lg="12">
                                        <div className="table-modifed ml-15 orders-table">
                                            <ListBuilder
                                                tabs={true}
                                                sort={true}
                                                tab1Name={'Orders'}
                                                // tab2Name={"B2C"}
                                                // tab3Name={"Employees"}
                                                // tab4Name={"Not sent"}
                                                additionalFilterFields={<>
                                                    <Label>
                                                        {'Type'} &nbsp;

                                                        <Input style={{ minWidth: 200 }} type="select" className="entries" value={params.employee ? 'employee' : params.b2c ? 'b2c' : params.refunder ? 'refunder' : params.vind ? 'vind' : params.notSent ? 'notSent' : 'b2b'}
                                                        onChange={(e) =>
                                                            this.updateMultipleParams([{ name: 'entries', value:"10" }, { name: 'page', value: 0 },{name: 'filter',value: ''},{name:'b2c',value:e.target.value === 'b2c'},{name:'employee',value:e.target.value === 'employee'}, {name:'notSent',value:e.target.value === 'notSent'}, {name:'vind',value:e.target.value === 'vind'}, {name:'refunder',value:e.target.value === 'refunder'}], true)

                                                        }>
                                                            <option value={'b2b'}>B2B</option>
                                                            <option value={'employee'}>Employees</option>
                                                            <option value={'b2c'}>B2C</option>
                                                            <option value={'refunder'}>Refunder</option>
                                                            <option value={'vind'}>Vind</option>
                                                            <option value={'notSent'}>Non sent</option>
                                                        </Input>
                                                    </Label>

                                                </>}

                                                // sort={false}
                                                header={'List'}
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.total}
                                                showNumeration={false}
                                                fields={[
                                                    { type: 'text', name: 'orderNumber', label: 'NO.', multilang: false, allowSort: true },
                                                    { type: 'text', name: 'billingData.company', label: 'COMPANY', multilang: false, allowSort: true },
                                                    { type: 'text', name: 'billingData.address1', label: 'ADDRESS', multilang: false, allowSort: true },
                                                    { type: 'text', name: 'billingData.city', label: 'CITY', multilang: false, allowSort: true },
                                                    { type: 'text', name: 'billingData.phone', label: 'PHONE'   , multilang: false, allowSort: true },
                                                    { type: 'text', name: 'billingData.email', label: 'EMAIL'   , multilang: false, allowSort: true },
                                                    { type: 'text', name: 'total', label: 'TOTAL'   , multilang: false, allowSort: true },

                                                ]}
                                                items={this.state.items.map((item) => {
                                                    return { ...item, total: item.total + ' ' + item.currency }
                                                })}
                                                rawItems={this.state.items}
                                                actions={
                                                    [
                                                        {
                                                            component: <Isvg src={editIcon} className="delete-icon" />,
                                                            onClick: (item) => {




                                                                this.setState({ form: null, orderShown: false, orderDetailsShown: true }, () => this.setState({ form: item }))

                                                            }
                                                        },
                                                    ]
                                                }
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            ></ListBuilder>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            :
                            null
                    }

                </div>


                {
                    this.state.orderDetailsShown && this.state.form ?
                        <div className="default-wrapper">

                            <Container fluid style={{ padding: 0 }}>
                                <Row>
                                    <Col lg="3">
                                        <div className="invoice-col col-header">
                                            <h6>Invoice to:</h6>
                                            <p>{this.state.form && this.state.form.billingData && this.state.form.billingData.company}</p>
                                            <p>{this.state.form && this.state.form.billingData && this.state.form.billingData.address1}</p>
                                            <p>{this.state.form && this.state.form.billingData && this.state.form.billingData.city} {this.state.form && this.state.form.billingData && this.state.form.billingData.postalCode}</p>
                                            <p>{this.state.form && this.state.form.billingData && this.state.form.billingData.phone}</p>
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="delivery-col col-header">
                                            <h6>Delivery to:</h6>
                                            <p>{this.state.form && this.state.form.shippingData && this.state.form.shippingData.company}</p>
                                            <p>{this.state.form && this.state.form.shippingData && this.state.form.shippingData.address1}</p>
                                            <p>{this.state.form && this.state.form.shippingData && this.state.form.shippingData.city} {this.state.form && this.state.form.shippingData && this.state.form.shippingData.postalCode}</p>
                                            <p>{this.state.form && this.state.form.shippingData && this.state.form.shippingData.phone}</p>
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="order-info-col col-header">
                                            <h6>Order Info</h6>
                                            <p>Date: {moment.unix(this.state.form.orderDate).format('YYYY-MM-DD HH:mm')}</p>
                                            <p>Order Reference: {this.state.form.orderReference ? this.state.form.orderReference : '/'}</p>
                                            <p>Delivery Date: {this.state.form.deliveryDate ? this.state.form.deliveryDate : '/'}</p>
                                            <p>Partial Delivery: {this.state.form.partialDelivery ? 'Yes' : 'No'}</p>
                                            <p>Freight Marks: {this.state.form.freightMarks ? this.state.form.freightMarks : '/'}</p>
                                            <p>Delivery method: {this.state.form.deliveryMethod ? this.state.form.deliveryMethod : '/'}</p>
                                            <p>Payment method: {this.state.form.paymentMethod ? this.state.form.paymentMethod : '/'}</p>
                                            <p>Discount code: {this.state.form.discountCode ? this.state.form.discountCode : '/'}</p>




                                        </div>
                                    </Col>
                                    {this.state.form.paymentMethod === 'Klarna' ?
                                        <Col lg="3">
                                            <div className="order-info-col col-header">
                                                <h6>Klarna Info</h6>
                                                <p>ID: {this.state.form.klarnaId ? this.state.form.klarnaId : '/'}</p>
                                                <p>Status: {this.state.form.klarnaStatus ? this.state.form.klarnaStatus : '/'}</p>



                                            </div>
                                        </Col>
                                        :
                                        null
                                    }

                                    <Col lg="12" className="no-table-default-style" style={{ paddingLeft: 0 }}>
                                        <h2>Order - {this.state.form.orderNumber}</h2>
                                        <ListBuilder
                                            tabs={false}
                                            sort={false}
                                            header={'List'}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.form && this.state.form.items && this.state.form.items.length}
                                            showNumeration={false}
                                            hideFilters
                                            hidePagination={true}
                                            fields={[
                                                { type: 'text', name: 'idx', label: 'NO.'   , multilang: false, allowSort: false },
                                                { type: 'text', name: 'name', label: 'PRODUCT' , multilang: false, allowSort: false },
                                                { type: 'text', name: 'quantity', label: 'QUANTITY' , multilang: false, allowSort: false },
                                                { type: 'text', name: 'price.value', label: 'PRICE' , multilang: false, allowSort: false },
                                                { type: 'text', name: 'price.vat', label: 'VAT [%]' , multilang: false, allowSort: false },
                                            ]}
                                            items={this.state.form && this.state.form.items.map((item, idx) => {
                                                return {
                                                    ...item,
                                                    idx: idx + 1,
                                                    name: <><span style={{ fontSize: 10 }}>{item.sku}</span><br />{item.name['sv']}</>,
                                                    test: item.price ? (item.price.value + item.price.vat) : 0,
                                                    price: {
                                                        ...item.price,
                                                        value: Number(item.price.value).toFixed(2),
                                                    }
                                                }
                                            })}
                                            rawItems={this.state.items}
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>

                                        <div className="table-sum">
                                            <div className="left">
                                                <h6>Amaunt without VAT:</h6>
                                                <h6>VAT:</h6>
                                                <h6>Total amount:</h6>
                                            </div>
                                            <div className="right">
                                                <h6><span>{Number(this.state.form.subtotal).toFixed(2)}</span></h6>
                                                <h6><span>{Number(this.state.form.vat).toFixed(2)}</span></h6>
                                                <h6><span>{this.state.form && Number(this.state.form.total).toFixed(2)}</span></h6>
                                            </div>

                                        </div>


                                    </Col>
                                    <Col>
                                        <Button onClick={() => {
                                            this._api('send', { data: this.state.form._id }, () => {
                                            });
                                        }}>SEND TO EXTEND</Button>

                                    </Col>
                                </Row>
                            </Container>

                        </div>
                        :
                        null
                }


            </div>
        )
    }
}

export default Page(Screen);

import React, {Component} from 'react';
import Isvg from 'react-inlinesvg';
import {Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from 'reactstrap';

import ProfileIcon from '../assets/profile.svg'
import ArrowDownIcon from '../assets/arrow-down.svg'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {


        return (
            <header className="header">
                <Container fluid style={{ padding: 0 }}>
                    <Row>
                        <Col lg="10">
                            {/* <div className="search">
                                <Isvg src={SearchIcon} />
                                <input type="text" placeholder="Search..." />
                            </div> */}
                        </Col>
                        <Col lg="2" className="profile-container">
                            <div className="profile">
                                <div className="profile-icon">
                                    <Isvg src={ProfileIcon} />
                                </div>
                                <h6>{this.props.uData && this.props.uData.username}</h6>
                                <div className="profile-settings">
                                    <Isvg src={ArrowDownIcon} />
                                    <span className="line"></span>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret>
                                            {this.props.lang.toUpperCase()}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.props.changeLang('en')}>EN</DropdownItem>
                                            <DropdownItem onClick={() => this.props.changeLang('sv')}>SV</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

export default Header;
